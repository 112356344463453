import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class MarineCommunications extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <Navbar />
                <main className="mainContent2">
                    <section className="whatWeDoSubpageSection">
                        <div className="whatWeDoSubpageSectionHeading">
                            <a href='/'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#225085' height="20" width="20"><path d="m9.042 16.042-5.438-5.417q-.146-.146-.208-.302-.063-.156-.063-.323t.063-.323q.062-.156.208-.302l5.438-5.437q.25-.25.604-.261.354-.01.625.261.25.25.25.614 0 .365-.25.615L6.312 9.125h9.126q.354 0 .614.26.26.261.26.615t-.26.615q-.26.26-.614.26H6.312l3.959 3.958q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25Z" /></svg>
                                Back</a>
                            {/* <h2>Marine communications</h2> */}
                        </div>
                        <div className='whatWeDoSubpageSectionContent'>
                            <div className='marineCommunicationsBanner'></div>
                            <p>
                                Avantel enabled Indian Navy and Indian Coast Guard to be connected with their operational forces
                                deployed at long ranges (ranging from 1000 to 3000 Kms) in their Area of Interest (AoI) providing highly
                                secured and reliable connectivity round the clock. The marine communication portfolio of Avantel
                                includes:
                                <br /><br /><br />
                                <ul>
                                    <li><a href='capabilities'>Mobile Satellite Services</a></li>
                                    <li><a href='capabilities'>UHF SATCOM</a></li>
                                    <li><a href='capabilities'>HF Communications</a></li>
                                </ul>
                            </p>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        )
    }
}

export default MarineCommunications