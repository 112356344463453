import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import swal from 'sweetalert';
import axios from 'axios';
import { BASEURL } from '../conf';

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            title: '',
            city: '',
            country: '',
            enquiryType: '',
            msg: ''
        }
    }
    handleSubmit() {
        let data = this.state;
        if(data.firstName == null || data.firstName == '' || data.lastName == null || data.lastName == '' || data.email == null || data.email == '' || data.phone == null || data.phone == '' || data.company == null || data.company == ''
        || data.city == null || data.city == '' || data.country == null || data.country == '' || data.title == null || data.title == '' || data.enquiryType == null || data.enquiryType == '' || data.msg == null || data.msg == '' ) {
            swal('Error', 'Please, fill all the fields in the form', 'error');
            return;
        }
        data.name = data.firstName + ' ' + data.lastName;
        swal({
            text: 'Please wait submitting the form',
            button: {
                visible: false
            },
            closeOnClickOutside: false,
            closeOnEsc: false
        })
        axios.post(`${BASEURL}/storeContact`, data)
        .then((res) => {
            if(res.status === 200) {
                swal('Success', 'Thank you for contacting us, successfully submitted the form', 'success')
                .then(function () {
                    window.location = 'contact'
                })
            }
        })
        .catch(err => {
            swal('Error', 'Error in submitting the form', 'error')
        })
    }
    render() {
        return (
            <div>
                <Navbar page="contact" />
                <section className='contactSection'>
                    <div className='contactHeading'>
                        <h2>We are here to help</h2>
                        <p>Leave a message and we will get back to you</p>
                    </div>
                    <form className='contactForm'>
                        <div className='row'>
                            <div className="form-group col-md-6 col-sm-12 colLeft">
                                <label htmlFor="firstName" className="form-label">FIRST NAME</label>
                                <input type="text" className="form-control" id="firstName" name='firstName' value={this.state.firstName} onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                            </div>
                            <div className="form-group col-md-6 col-sm-12 colRight">
                                <label htmlFor="lastName" className="form-label">LAST NAME</label>
                                <input type="text" className="form-control" id="lastName" name='lastName' value={this.state.lastName} onChange={(e) => this.setState({[e.target.name]: e.target.value})}  />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="email" className="form-label">EMAIL</label>
                                <input type="email" className="form-control" id="email" name='email' value={this.state.email} onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="company" className="form-label">COMPANY</label>
                                <input type="text" className="form-control" id="company" name='company' value={this.state.company} onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="title" className="form-label">TITLE</label>
                                <input type="text" className="form-control" id="title" name='title' value={this.state.title} onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="phone" className="form-label">PHONE</label>
                                <input type="text" className="form-control" id="phone" name='phone' value={this.state.phone} onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="country" className="form-label">COUNTRY</label>
                                <input type="text" className="form-control" id="country" name='country' value={this.state.country} onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="city" className="form-label">CITY</label>
                                <input type="text" className="form-control" id="city" name='city' value={this.state.city} onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="enquiryType" className="form-label">ENQUIRY TYPE</label>
                                <select className="form-select" id='enquiryType' name='enquiryType' value={this.state.enquiryType} onChange={(e) => this.setState({[e.target.name]: e.target.value})}>
                                    <option selected>Select enquiry type</option>
                                    <option value={"Marketing"}>Marketing</option>
                                    <option value={"Procurement"}>Procurement</option>
                                    <option value={"Human Resources"}>Human Resources</option>
                                    <option value={"General"}>General</option>
                                    <option value={"Investors"}>Investors</option>
                                </select>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="message" className="form-label">MESSAGE</label>
                                <textarea className="form-control" id="message" name='msg' cols={4} value={this.state.msg} onChange={(e) => this.setState({[e.target.name]: e.target.value})}></textarea>
                            </div>
                            <div className='submitBtnDiv'>
                                <button type='button' className='btn custom-primary' onClick={() => this.handleSubmit()}>SUBMIT ENQUIRY</button>
                            </div>
                        </div>
                    </form>
                </section>
                <Footer />
            </div>
        )
    }
}

export default Contact