import React, { Component } from 'react';


export class Xponder extends Component {
    render() {
        return (
            <div class="notfoundmain">
                <div class="notfounddiv">
                        Xponder
                </div>
            </div>
        )
    }
}

export default Xponder