import PropTypes from 'prop-types'
import React, { Component, createRef } from 'react'
import Navbar from '../components/Navbar'
import './Careers.css'
import { BASEURL } from '../conf';
import swal from 'sweetalert';
import Footer from '../components/Footer';


export class Careers extends Component {
  // static propTypes = {}
  constructor(props) {
    super(props);
    // Create a ref for the file input element
    // this.fileInputRef = React.createRef();
    this.detailsRef = createRef();
    this.fileInputRef = React.createRef();
     // Bind methods
     this.handleButtonClick = this.handleButtonClick.bind(this);
     this.handleFileChange = this.handleFileChange.bind(this);
     this.handleSubmitResume = this.handleSubmitResume.bind(this);
  }

  state = {
    HyderabadJobs: [
      {
        id: 1, role: 'Java Senior Developer',
        location: 'Hyderabad, India',
        skills: 'Java, Spring Framework (BOOT /MVC ), Hibernate, MS SQL, MYSQL',
        qualification: 'B.Tech/B.Sc/MSc/MCA',
        experience: 'min: 05 years, max: 10 years',
        description: [{ key: 1, point: 'General understanding of business needs and work closely with all business teams (procurement, production, QA, finance, marketing, etc.) to develop and upgrade in-house ERP ' }
          , { key: 2, point: 'Write well-designed, efficient code' },
        { key: 3, point: 'Create and nurture an environment of consistent learning and growth ' },
        { key: 4, point: 'Use critical thinking skills to solve difficult and sometimes ambiguous business problems ' },
        { key: 5, point: 'Participate in the requirement gathering process for new software functionality to ensure that definitions of new functionality are clearly defined and understood' },
        { key: 6, point: 'Ensure designs are consistent with our development standards and industry guidelines' }
        ]
      },
      {
        id: 2, role: 'FPGA Developer',
        location: 'Hyderabad, India',
        qualification: 'B.Tech/ M. Tech. degree in Embedded Systems / Signal processing / VLSI ',
        experience: 'Should be trained in C & Embedded courses also on FPGA',
        responsibilities: [{
          mandatory: [{ key: 1, point: 'Coding and Debugging in C language /Coding and Debugging in Verilog - VHDL' }
            , { key: 2, point: 'Knowledge of signal processing algorithms related to Digital communication such as Digital Filters, FFT, Modulation, Demodulation, Carrier / Symbol Tracking etc.' },
          { key: 3, point: 'Thorough knowledge on Xilinx, Spartan 6 & 7 Board. A hand on experience is a must on above.' },
          { key: 4, point: 'Programming knowledge of Hardware and Firmware' },
          { key: 5, point: 'Development Life Cycle processes for embedded systems' },
          ],
          prefarable: [
            { key: 1, point: 'Realization of Real Time Digital Signal Processing and / Or' }
            , { key: 2, point: 'Realization of real time Digital Communications' },
            { key: 3, point: 'Knowledge in FPGA is Mandatory' },
          ]
        },
        ]
      }
    ],
    selected_job: {
      id: 1, role: 'Java Senior Developer',
      location: 'Hyderabad',
      skills: 'Java, Spring Framework (BOOT /MVC ), Hibernate, MS SQL, MYSQL',
      qualification: 'B.Tech/B.Sc/MSc/MCA',
      experience: 'min: 05 years, max: 10 years',
      description: [{ key: 1, point: 'General understanding of business needs and work closely with all business teams (procurement, production, QA, finance, marketing, etc.) to develop and upgrade in-house ERP ' }
        , { key: 2, point: 'Write well-designed, efficient code' },
      { key: 3, point: 'Create and nurture an environment of consistent learning and growth ' },
      { key: 4, point: 'Use critical thinking skills to solve difficult and sometimes ambiguous business problems ' },
      { key: 5, point: 'Participate in the requirement gathering process for new software functionality to ensure that definitions of new functionality are clearly defined and understood' },
      { key: 6, point: 'Ensure designs are consistent with our development standards and industry guidelines' }
      ]
    },
    selectedFile: null,
    selectedFileName: '',
  };
  // Method to handle item click
  handleClick = (item) => {
    this.setState({ selected_job: item },
      () => {
        // Scroll to the details section after selecting an item
        if (window.innerWidth < 640 && this.detailsRef.current) {
          this.detailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          setTimeout(() => {
            window.scrollBy(0, -28);
          }, 300);
        }
      }
    );
  };

  // handleButtonClick = () => {
  //   if (this.fileInputRef.current) {
  //     this.fileInputRef.current.click();
  //   }
  // };
  // handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     console.log("Selected file:", file.name);
  //     // Handle the file here (e.g., upload it, read its contents, etc.)
  //     this.redirectToEmail();
  //   }
  // };

  redirectToEmail = () => {
    console.log('mail toooo')
    const recipientEmail = 'hello@simply.science';
    const subject = 'Resume Submission';
    const body = 'Hello, \n\nPlease find my resume attached.';

    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  handleButtonClick() {
    // Trigger the file input click
    this.fileInputRef.current.click();
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      const UPLOAD_RESUME_SIZE_LIMIT = 2 * 1024 * 1024; // 2MB in bytes
      const allowedFileTypes = ['application/pdf', 
                                'application/msword', 
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  
      if (file.size > UPLOAD_RESUME_SIZE_LIMIT) {
        swal('Error', 'File size exceeds 2MB limit.', 'error');
        return;
      }
  
      if (!allowedFileTypes.includes(file.type)) {
        swal('Error', 'Invalid file type. Only PDF, DOC, and DOCX files are allowed.', 'error');
        return;
      }
  
      // Update the state with the selected file
      this.setState({
        selectedFile: file,
        selectedFileName: file.name
      });
    }
  }
  

  handleSubmitResume = async () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      console.log('selectedFile',selectedFile)
      swal({
        text: 'Please wait submitting the resume',
        button: {
            visible: false
        },
        closeOnClickOutside: false,
        closeOnEsc: false
    })
      const formData = new FormData();
      formData.append('resume', selectedFile);
      formData.append('jobRole', this.state.selected_job.role);
      try {
        const response = await fetch(`${BASEURL}/resume`, {
          method: 'POST',
          body: formData,
        });
  
        const result = await response.json();
        console.log('Success:', result);
        if(result.success){
          swal('Success', 'Resume Uploaded', 'success')
          .then(function () {
              // window.location = 'careers'
          })
        }
      } catch (error) {
        console.error('Error:', error);
        swal('Error', 'Error in submitting the Resume', 'error')
      }
    }
  }

  render() {
    const { HyderabadJobs, selected_job, selectedFileName } = this.state;

    return (
      <div>
        <Navbar page="careers" />
        <main className="mainContent1">
          <section className="servicesSection">
            <div className="careerSectionHeading">
              <h2>Find open roles</h2>
              <p>AVANTEL LIMITED is a technology driven public limited company with focus on developing innovative wireless communication products and solutions to meet unique requirements of defense, railways, and telecom sectors.  The organization is certified against AS 9100D and ISO 9001:2015 standards for Quality Management System.</p>
            </div>
          </section>
          <section className='careerSection'>
            <div className='locationsSection'>
              <div className='locationSectionHeading p-20'>
                <h4>Hyderabad</h4>
                <ul>
                  {HyderabadJobs.map(item => (
                    <li className='jobItem' key={item.id}
                      style={{
                        cursor: 'pointer',
                        color: item.id === selected_job.id ? 'blue' : 'black',
                        // borderBottom: item.id === selected_job.id ? '1px solid blue' : '1px solid rgb(225, 217, 217)',
                      }}
                      onClick={() => this.handleClick(item)}>
                      {item.role}
                    </li>
                  ))}
                </ul>
              </div >
              <hr className='hrdivison'></hr>
              <div className='locationSectionHeading p-20'>
                <h4>Visakhapatnam</h4>
              </div>
              <hr className='hrdivison'></hr>
              <div className='locationSectionHeading p-20'>
                <h4>Delhi</h4>
              </div>
            </div>

            <div className='jobDescription' ref={this.detailsRef}>
              {
                selected_job &&
                <>
                  <div className='job_heading_sec'>
                    <h4>{selected_job.role}</h4>
                    {
                      selected_job.location && <div>{selected_job.location}</div>
                    }
                  </div>
                  <div className='job_body_sec'>
                    {selected_job.skills &&
                      <div>
                        <div className='skill_heading'>Skills:</div>
                        <ul>
                          <li className='skill_item'>{selected_job.skills}</li>
                        </ul>
                      </div>
                    }
                    {
                      selected_job.experience && <div>
                        <div className='skill_heading'>Experience Level:</div>
                        <ul>
                          <li className='skill_item'>{selected_job.experience}</li>
                        </ul>
                      </div>
                    }
                    {
                      selected_job.qualification && <div>
                        <div className='skill_heading'>Qualification:</div>
                        <ul>
                          <li className='skill_item'>{selected_job.qualification}</li>
                        </ul>
                      </div>
                    }
                    {
                      selected_job.description && (<div>
                        <div className='skill_heading'>Job Description:</div>
                        <ul>
                          {
                            selected_job.description.map((item, index) => {
                              return (
                                <li key={index} className='skill_item'>{item.point}</li>
                              )
                            })
                          }
                        </ul>
                      </div>)
                    }
                    {
                      selected_job.responsibilities && (<div>
                        <div className='skill_heading'>Desired Skills / Responsibilities:</div>
                        {
                          selected_job.responsibilities[0].mandatory && (
                            <>
                              <div className='sub_heading'>Mandatory Skills/ Knowledge :</div>
                              <ul>
                                {
                                  selected_job.responsibilities[0].mandatory.map((item, index) => {
                                    return (
                                      <li key={index} className='skill_item'>{item.point}</li>
                                    )
                                  })
                                }
                              </ul>
                            </>)
                        }
                        {
                          selected_job.responsibilities[0].prefarable && (<>
                            <div className='sub_heading'>Preferable: The Experience must be in the area(s) of</div>
                            <ul>
                              {
                                selected_job.responsibilities[0].prefarable.map((item, index) => {
                                  return (
                                    <li key={index} className='skill_item'>{item.point}</li>
                                  )
                                })
                              }
                            </ul>
                          </>)
                        }
                      </div>)
                    }
                  </div>
                </>
              }
              <div className="upload-container">
               <div className='btn_hint'>
               <button className="upload-button" onClick={this.handleButtonClick}>
                  Choose file
                </button>
                <span className="file-hint">(as .pdf, .doc, or .docx file)</span>
               </div>
                <input
                  type="file"
                  ref={this.fileInputRef}
                  style={{ display: 'none' }}
                  accept=".pdf,.docx, .doc"
                  onChange={this.handleFileChange}
                />
                {/* Display the selected file name */}
                {selectedFileName && (
                  <p className="file-name">
                    <span style={{fontWeight:500}}>Selected file:</span> {selectedFileName}
                  </p>
                )}


                <div className='btn_sec' onClick={this.handleSubmitResume}>
                  <a className='upload_btn'>Submit Resume</a>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>

    )
  }
}

export default Careers