import React, { Component, createRef } from 'react';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import './Careers.js'
import { BASEURL } from '../conf';

import swal from 'sweetalert';


export class JobDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopupVisible: false, // State to control popup visibility
            job: {}, // Placeholder for job data
        };
        // Create a ref for the file input element
        // this.fileInputRef = React.createRef();
        this.detailsRef = createRef();
        this.fileInputRef = React.createRef();
        // Bind methods
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmitResume = this.handleSubmitResume.bind(this);
    }


    togglePopup = () => {
        this.setState((prevState) => ({ isPopupVisible: !prevState.isPopupVisible }));
    };

    redirectToEmail = () => {
        console.log('mail toooo')
        const recipientEmail = 'hello@simply.science';
        const subject = 'Resume Submission';
        const body = 'Hello, \n\nPlease find my resume attached.';

        const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    handleButtonClick() {
        // Trigger the file input click
        this.fileInputRef.current.click();
    }

    handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const UPLOAD_RESUME_SIZE_LIMIT = 2 * 1024 * 1024; // 2MB in bytes
            const allowedFileTypes = ['application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

            if (file.size > UPLOAD_RESUME_SIZE_LIMIT) {
                swal('Error', 'File size exceeds 2MB limit.', 'error');
                return;
            }

            if (!allowedFileTypes.includes(file.type)) {
                swal('Error', 'Invalid file type. Only PDF, DOC, and DOCX files are allowed.', 'error');
                return;
            }

            // Update the state with the selected file
            this.setState({
                selectedFile: file,
                selectedFileName: file.name
            });
        }
    }
    componentDidMount() {
        // Assuming 'job' is passed via props
        const job = this.props.location?.state || {};
        this.setState({ job });
    }


    handleSubmitResume = async () => {
        const { job, selectedFile } = this.state;
        if (selectedFile) {
            console.log('this.state.job.role', this.state.job.role)
            console.log('selectedFile', selectedFile)
            swal({
                text: 'Please wait submitting the resume',
                button: {
                    visible: false
                },
                closeOnClickOutside: false,
                closeOnEsc: false
            })
            const formData = new FormData();
            formData.append('resume', selectedFile);
            formData.append('jobRole', this.state.job.role);
            console.log('formData', formData)
            try {
                // const response = await fetch(`${BASEURL}/resume`, {
                //   method: 'POST',
                //   body: formData,
                // });

                // const result = await response.json();
                // console.log('Success:', result);
                const result = { success: true }
                if (result.success) {
                    swal('Success', 'Resume Uploaded', 'success')
                        .then(function () {
                            // window.location = 'careers'
                            this.isPopupVisible = false
                        })
                }
            } catch (error) {
                console.error('Error:', error);
                swal('Error', 'Error in submitting the Resume', 'error')
            }
        }
    }
    render() {
        const job = this.props.location.state; // Access the 'job' object
        console.log('job', job)
        const { isPopupVisible } = this.state;
        const { HyderabadJobs, selected_job, selectedFileName } = this.state;


        return (
            <div>
                <Navbar page="careers" />
                <div className='job_container'>

                    <div className='job_heading_sec'>

                        <h4>{job.role}</h4>
                        <div className='job-roles'>
                            <div className='job-header'>
                                <div className='jobtype'>
                                    <img src={job.typeImage} alt="Job Type" className='jobImage' />
                                    {job.type}
                                </div>
                                <div className='joblocation'>
                                    <img
                                        src={job.locationImage}
                                        alt="Location"
                                        className='locationImages'
                                    />
                                    {job.location}
                                </div>
                            </div>

                            <div className="apply_now">
                                <button className="upload_btn" onClick={this.togglePopup}>
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='job_body_sec'>
                        {
                            job.industry &&
                            <div>
                                <div className='skill_heading'>Industry:</div>

                                <ul>
                                    <li className='skill_item'>{job.industry}</li>
                                </ul>
                            </div>
                        }
                        {job.skills &&
                            <div>
                                <div className='skill_heading'>Skills:</div>
                                <ul>
                                    {
                                        job.skills.map((item, index) => {
                                            return (
                                                <li key={index} className='skill_item'>{item.point}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }

                        {job.preferable &&
                            <div>
                                <div className='skill_heading'>Preferable:</div>
                                <ul>
                                    {
                                        job.preferable.map((item, index) => {
                                            return (
                                                <li key={index} className='skill_item'>{item.point}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }



                        {job.desiredprofile &&
                            <div>
                                <div className='skill_heading'>Desired profile of the candidate:</div>
                                <ul>
                                    {
                                        job.desiredprofile.map((item, index) => {
                                            return (
                                                <li key={index} className='skill_item'>{item.point}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        {
                            job.experience && <div>
                                <div className='skill_heading'>Experience Level:</div>
                                <ul>
                                    <li className='skill_item'>{job.experience}</li>
                                </ul>
                            </div>
                        }
                        {
                            job.qualification && <div>
                                <div className='skill_heading'>Qualification:</div>
                                <ul>
                                    <li className='skill_item'>{job.qualification}</li>
                                </ul>
                            </div>
                        }
                        {
                            job.description && (<div>
                                <div className='skill_heading'>Job Description:</div>
                                <ul>
                                    {
                                        job.description.map((item, index) => {
                                            return (
                                                <li key={index} className='skill_item'>{item.point}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>)
                        }
                        {
                            job.responsibilities && (<div>
                                <div className='skill_heading'> Skills / Responsibilities:</div>
                                {
                                    job.responsibilities[0].mandatory && (
                                        <>
                                            <div className='sub_heading'>Mandatory Skills/ Knowledge :</div>
                                            <ul>
                                                {
                                                    job.responsibilities[0].mandatory.map((item, index) => {
                                                        return (
                                                            <li key={index} className='skill_item'>{item.point}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>)
                                }
                                {
                                    job.responsibilities[0].prefarable && (<>
                                        <div className='sub_heading'>Preferable: The Experience must be in the area(s) of</div>
                                        <ul>
                                            {
                                                job.responsibilities[0].prefarable.map((item, index) => {
                                                    return (
                                                        <li key={index} className='skill_item'>{item.point}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </>)
                                }
                                <div className='skills_item'>We are looking for a creative individual skilled in both video editing and graphic design to tell stories through visual content.
                                    The ideal candidate should have:</div>
                                <ul>
                                    {
                                        job.responsibilities.map((item, index) => {
                                            return (
                                                <li key={index} className='skill_item'>{item.point}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>)
                        }
                    </div>


                </div>
                {isPopupVisible && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <div className='popup-header'>
                                <h3>Select Your Resume</h3>
                                <button
                                    className="close-button"
                                    onClick={() => this.setState({ isPopupVisible: false })} // Close the popup
                                >
                                    <img src="/assets/Vector.png" width={"30%"} alt="homescreen" />
                                </button>
                            </div>
                            <div className="upload-container">
                                <div className='btn_hint'>
                                    <button className="upload-button" onClick={this.handleButtonClick}>
                                        Choose file
                                    </button>
                                    <span className="file-hint">(as .pdf, .doc, or .docx file)</span>
                                </div>
                                <input
                                    type="file"
                                    ref={this.fileInputRef}
                                    style={{ display: 'none' }}
                                    accept=".pdf,.docx, .doc"
                                    onChange={this.handleFileChange}
                                />
                                {/* Display the selected file name */}
                                {selectedFileName && (
                                    <p className="file-name">
                                        <span style={{ fontWeight: 500 }}>Selected file:</span> {selectedFileName}
                                    </p>
                                )}


                                <div className='btn_sec' onClick={this.handleSubmitResume}>
                                    <a className='upload_btn'>Submit Resume</a>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
                <Footer />
            </div>

        )
    }
}

export default JobDescription

