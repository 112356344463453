import React, { Component } from 'react'
import './Indianrailnavigator.css'

export class Indianrailnavigator extends Component {
  render() {
  return (
    <div>
        <div class="container">
        <h1>Indian Rail Navigator</h1>
        </div>
        <div class="container">
            <h3>Introduction</h3>
            <p class="introduction-para">The IRN is a ruggedized Single board computer-based panel PC. 
                It operates on 24V DC derived from Power Supply Module. 
                IRN consists of the following additional sub-systems. </p>
        </div>
        <div class="container specifications">
            <h3>Specifications</h3>
            <table class="table table-bordered table-responsive table-hover">
                <thead class="table-headings">
                <tr>
                    <th class="table-alignment">S.no</th>
                    <th class="table-alignment">Component</th>
                    <th class="table-alignment">Parameter</th>
                    <th>Specifications</th>
                </tr>
                </thead>
                <tbody>
				<tr>
                </tr><tr>
                    <td rowspan="4">1</td>
                    <td rowspan="4">SBC</td>
                </tr>
                <tr>
                    <td>Make</td>
                    <td>Lex Systems</td>
                </tr>
                <tr>
                    <td>Part number</td>
                    <td>21640 HW</td>
                </tr>
				
				<tr>
				 </tr><tr>
                    </tr><tr class="table-alignment"><td rowspan="11">2</td></tr>
                    <tr class="table-alignment"><td rowspan="11">Processor</td></tr>
                
                <tr>
                    <td>Make</td>
                    <td>INTEL</td>
                </tr>
                <tr>
                    <td>SoC Type</td>
                    <td>Intel Atom x6413E processor</td>
                </tr>
                <tr>
                    <td>Frequency</td>
                    <td>1.50 GHz @ base freq/ 3.00GHz @ Turbo freq</td>
                </tr>
                <tr>
                    <td>Graphics</td>
                    <td>UHD graphics 10<sup>th</sup> Gen Intel 500MHz/750MHz</td>
                </tr>
                <tr>
                    <td>Cache</td>
                    <td>1.5MB L2 Cache</td>
                </tr>
                <tr>
                    <td>TDP</td>
                    <td>9W</td>
                </tr>
                <tr>
                    <td>Memory Type</td>
                    <td>LP DDR4</td>
                </tr>
                <tr>
                    <td>Interfaces</td>
                    <td>LAN X 2, Port RS232/RS422/RS485 X 1 and RS232 X 3, USB 3.0 type-C X 1, USB2.0 X 5, M.2 Bkey SATA</td>
                </tr>
                <tr>
                    <td>Instruction Set</td>
                    <td>64-bit</td>
                </tr>
				
              <tr>
				</tr><tr>
                    </tr><tr class="table-alignment"><td rowspan="4">3</td></tr>
                    <tr class="table-alignment"><td rowspan="4">RAM</td></tr>
                
                <tr>
                    <td>Type</td>
                    <td>On-Board LPDDR4</td>
                </tr>
                <tr>
                    <td>Memory</td>
                    <td>8GB</td>
                </tr>
                <tr>
                    </tr><tr class="table-alignment"><td rowspan="7">4</td></tr>
                    <tr class="table-alignment"><td rowspan="7">SSD</td></tr>
                
                <tr>
                    <td>Make</td>
                    <td>Phioson</td>
                </tr>
                <tr>
                    <td>Part#</td>
                    <td>SSDPB-SSL 128GMILES-SB1-NO</td>
                </tr>
                <tr>
                    <td>Storage</td>
                    <td>128 GB</td>
                </tr>
                <tr>
                    <td>Interface</td>
                    <td>M.2 Key B SATA SSD</td>
                </tr>
                <tr>
                    <td>Form Factor</td>
                    <td>M.2 2242</td>
                </tr>
			  
              <tr>
				</tr><tr>
                    </tr><tr class="table-alignment"><td rowspan="8">5</td></tr>
                    <tr class="table-alignment"><td rowspan="8">5G Modems</td></tr>
                
                <tr>
                    <td>Make</td>
                    <td>Telit</td>
                </tr>
                <tr>
                    <td>Part#</td>
                    <td>FN990A28</td>
                </tr>
                <tr>
                    <td>Interface Type</td>
                    <td>M.2 Type-B</td>
                </tr>
                <tr>
                    <td>Interface signal</td>
                    <td>USB 2.0</td>
                </tr>
                <tr>
                    <td>Form Factor</td>
                    <td>M.2, 30*52*2.25 mm</td>
                </tr>
                <tr>
                    <td>Networks</td>
                    <td>5G sub-6/4G/3G</td>
                </tr>
				
			  
			  <tr>
				 </tr><tr>
                    </tr><tr class="table-alignment"><td rowspan="8">6</td></tr>
                    <tr class="table-alignment"><td rowspan="8">Touch LCD Display</td></tr>
                
                <tr>
                    <td>Make</td>
                    <td>LITEMAX</td>
                </tr>
                <tr>
                    <td>Size</td>
                    <td>7 Inch display</td>
                </tr>
                <tr>
                    <td>Part#</td>
                    <td>IPPK-0702-MOD0-NP00-AT01</td>
                </tr>
                <tr>
                    <td>Resolution</td>
                    <td>1024 x 600 (16:9)</td>
                </tr>
                <tr>
                    <td>Display Interface</td>
                    <td>LVDS signal</td>
                </tr>
                <tr>
                    <td>Touch Interface</td>
                    <td>USB 2.0</td>
                </tr>
			  
                </tbody>
            </table>
        </div>
    </div>
  )
}
}
 
export default Indianrailnavigator