import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './App.css'
import About from './screens/About'
import Aerospace from './screens/Aerospace'
import Contact from './screens/Contact'
import CustomSolutions from './screens/CustomSolutions'
import Home from './screens/Home'
import Investors from './screens/Investors'
import MarineCommunications from './screens/MarineCommunications'
import News from './screens/News'
import PrivacyPolicy from './screens/PrivacyPolicy'
import QualityPolicy from './screens/QualityPolicy'
import Services from './screens/Services'
import TermsOfUse from './screens/TermsOfUse'
import NotFoundPage from './screens/NotFound' // Import the NotFound component
import Xponder from './screens/Xponder'
import Careers from './screens/Careers'
import Nabhmitraprivacypolicy from './screens/Nabhmitraprivacypolicy'
import Indianrailnavigator from './screens/Indianrailnavigator'
import JobDescription from './screens/JobDescription'
class App extends Component {
  constructor () {
    super()
    this.state = {}
  }

  render () {
    return (
      <div>
        <BrowserRouter>
          <div>
            <Switch>
              <Route path='/' component={Home} exact />
              <Route path='/capabilities' component={Services} exact />
              <Route path='/news' component={News} exact />
              <Route path='/about' component={About} exact />
              <Route path='/aerospace' component={Aerospace} exact />
              <Route
                path='/marineCommunications'
                component={MarineCommunications}
                exact
              />
              <Route
                path='/customSolutions'
                component={CustomSolutions}
                exact
              />
              <Route path='/contact' component={Contact} exact />
              <Route path='/careers' component={Careers} exact />
              <Route path='/investors' component={Investors} exact />
              <Route path='/qualityPolicy' component={QualityPolicy} exact />
              <Route path='/privacyPolicy' component={PrivacyPolicy} exact />
              <Route path='/termsOfUse' component={TermsOfUse} exact />
              <Route path='/xponder' component={Xponder} exact />
              <Route
                path='/nabhmitra/privacypolicy'
                component={Nabhmitraprivacypolicy}
                exact
              />
              <Route
                path='/prod/internal/projects/irn/indianrailnavigator'
                component={Indianrailnavigator}
                exact
              />
              <Route
                path='/jobdescription'
                component={JobDescription}
                exact
              />
              <Route path='*' component={NotFoundPage} exact />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    )
  }
}

export default App
