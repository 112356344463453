import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="footer">
                <div className="footer-top">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-6 footer-copyright">
                            <h4>©2022 avantel limited</h4>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 footer-links">
                            <ul>
                                {/* <li><a href="#">Careers</a></li> */}
                                <li><a href="qualityPolicy">Quality policy</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 footer-links">
                            <ul>
                                <li><a href="#">Collaterals</a></li>
                                <li><a href="termsOfUse">Terms of use</a></li>
                                <li><a href="privacyPolicy">Privacy</a></li>
                                <li><a href="#">Sitemap</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 footer-sm">
                            <h4>FOLLOW US</h4>
                            <div className="social-media-links">
                                <a href="#" className="twitter"><img src='assets/twitter.png' /></a>
                                <a href="#" className="fb"><img src='assets/fb.png' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer