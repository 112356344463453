import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class CustomSolutions extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <Navbar />
                <main className="mainContent2">
                    <section className="whatWeDoSubpageSection">
                        <div className="whatWeDoSubpageSectionHeading">
                            <a href='/'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#225085' height="20" width="20"><path d="m9.042 16.042-5.438-5.417q-.146-.146-.208-.302-.063-.156-.063-.323t.063-.323q.062-.156.208-.302l5.438-5.437q.25-.25.604-.261.354-.01.625.261.25.25.25.614 0 .365-.25.615L6.312 9.125h9.126q.354 0 .614.26.26.261.26.615t-.26.615q-.26.26-.614.26H6.312l3.959 3.958q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25Z" /></svg>
                                Back</a>
                            {/* <h2>Custom Innovative Solutions</h2> */}
                        </div>
                        <div className='whatWeDoSubpageSectionContent'>
                            <div className='customSolutionsBanner'></div>
                            <p>
                                Avantel is in the forefront of innovation and offers various unique solutions to meet the special requirements of its customers. Instead of offering similar products as that of its competitors, Avantel adopts a Blue Ocean Strategy to carefully position itself where there is minimal or no competition.  This strategy helps Avantel in positioning itself ahead of its competitors and posing very high risk for late entrants in view of developmental cycle times and low volumes of business. Avantel R & D is recognized by DSIR, GoI as Centre of Excellence for undertaking R & D work in defence technologies. Avantel R & D facilities are state of the art and has a highly skilled & experienced talent pool capable of undertaking focused / advanced R & D activities in new technologies.   Avantel follows a two pronged strategy in development of its products viz. customer driven (customer RFI / RFP) and new product development initiated by Avantel.  Various innovative solutions currently deployed are as under:
                                <br /><br /><br />
                                <ul>
                                    <li><a href='capabilities'>MSS Network</a></li>
                                    <li><a href='capabilities'>UHF SATCOM Network</a></li>
                                    <li><a href='capabilities'>Wind Profiler Radars</a></li>
                                    <li><a href='capabilities'>Real-time Information System for Indian Railways</a></li>
                                    <li><a href='capabilities'>SAHAYAK Air Droppable Containers</a></li>
                                    <li><a href='capabilities'>ERM-SAT</a></li>
                                    <li><a href='capabilities'>Ocean Gauges & Tide Gauge Applications</a></li>
                                    <li><a href='capabilities'>Visual Databases for Aircraft Simulators</a></li>
                                </ul>
                            </p>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        )
    }
}

export default CustomSolutions