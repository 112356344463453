import React, { Component } from 'react';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class About extends Component {
    render() {
        return (
            <div>
                <Navbar page="about" />
                <main className="mainContent3">
                    <section className="about-main">
                    </section>
                    <section className='aboutContent'>
                        <p>We have often wondered as to what keeps us moving forward. Is it the customers? Is it strategy? Is it technology? Is it the approach? Is it the spirit? Or is it the people? Or best practices?</p>
                        
                        <p>In fact, it is all of them in the right proportion that drives us ahead. But, the glue that binds us is a shared sense of purpose. A common belief in adding value to the knowledge base of society. A belief that finds expression in everything that we do. Day in Day out.</p>
                    </section>
                    <section className='aboutUsSection'>
                        <div className='aboutUsDiv1'>
                            <div className='row'>
                                <div className='col-md-4 aboutUsCol colPaddingleft'>
                                    <h4><img className='aboutIcon' src='../assets/about/Purpose icon1.png' />Purpose</h4>
                                    <p>
                                        “We Design and Develop Digital Solutions to enhance quality of life using innovative technologies”.
                                    </p>
                                </div>
                                <div className='col-md-4 aboutUsCol colPaddingMiddle'>
                                    <h4><img className='aboutIcon' src='../assets/about/Vision icon1.png' />Vision</h4>
                                    <p>
                                        “Well-being of human kind depends on seamless Integration of Intelligence in to multiple technologies across varied domains for sustainable quality of life on our Planet Earth”.
                                    </p>
                                </div>
                                <div className='col-md-4 aboutUsCol colPaddingRight'>
                                    <h4><img className='aboutIcon' src='../assets/about/Mission icon1.png' />Mission</h4>
                                    <p>
                                        “Centre of Excellence through innovation to achieve the dream of self-reliant, strong and prosperous India”.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="aboutContentSection">
                        <div className='aboutContentDiv'>
                            <div className='aboutContent'>
                                <div className="row">
                                    <div className="col-md-4 aboutContentColLeft">
                                        <div className='aboutImgcol'>
                                            <div className='aboutImg1'></div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 aboutContentColRight">
                                        <h4>Honoring our roots, shaping our future</h4>
                                        <p>
                                            Avantel has been supporting the strategic sector since three decades and has rolled out many unique products / solutions to Indian Defence Services and allied establishments. The company in its initial years, has manufactured various building blocks of the Radios. 
                                            <br></br>
                                            From early 2000 onwards; the company has reoriented itself and started offering system based solutions to its customers in four main verticals i.e. Satellite Communications, HF Communications, Electronic Warfare and Radar systems respectively. Presently, the company is in the process of enhancing its portfolio and developing SCA compliant Software Defined Radios, High Power HF systems, Air Defence Radars and Small Satellites.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='aboutContentDiv'>
                            <div className='aboutContent'>
                                <div className="row">
                                    <div className="col-md-4 aboutContentColLeft">
                                        <div className='aboutImgcol'>
                                            <div className='aboutImg2'></div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 aboutContentCol">
                                        <h4><img className='aboutIcon1' src='../assets/about/Excellence icon1.png' />Excellence</h4>
                                        <p>
                                            We aim for excellence in everything we do. We build indigenous solutions which are state of the art yet very competitive. Our R & D centre is recognized as “Center for Excellence” for undertaking development of contemporary technologies in the areas of areas of electrical engineering, communication and computing.
                                        </p>
                                    </div>
                                    <div className="col-md-4 aboutContentCol colRight ">
                                        <h4><img className='aboutIcon1' src='../assets/about/Respect icon1.png' />Respect</h4>
                                        <p>
                                            We ensure mutual respect along the hierarchy for free flow of ideas and information resulting in ownership across levels. Each employee is made to feel that he is critical resource and he in turn gives in his best accelerating our growth
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='aboutContent1'>
                                <div className="row">
                                    <div className="col-md-4 aboutContentCol colLeft">
                                    </div>
                                    <div className="col-md-4 aboutContentCol">
                                        <h4><img className='aboutIcon1' src='../assets/about/Teamwork icon1.png' />Teamwork</h4>
                                        <p>
                                            Avantel strength lies in its people and many employees have been with the organization for more than a decade plus which is a testimony of the work ethics and culture in the organization. Our people are our partners in progress and critical to Business Success.
                                        </p>
                                    </div>
                                    <div className="col-md-4 aboutContentCol colRight">
                                        <h4><img className='aboutIcon1' src='../assets/about/Commitment icon1.png' />Commitment</h4>
                                        <p>
                                            Our motto is “Nation First” and all our energies are directed in creating value to our customers. Our dream is to contribute our best towards self reliance and hence we constantly innovate in whatever we choose to do. We are also committed to enhance the quality of solutions  we offer which enable conservation of our natural resources.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='ourOfficesSection'>
                        <div className='ourOfficesDiv'>
                            <h2>Our Offices</h2>
                        </div>
                        <div className='ourOfficesDiv1'>
                            <div className='row'>
                                <div className='col-md-4 ourOfficesCol colPaddingleft'>
                                    <h4>Hyderabad</h4>
                                    <p>
                                        Plot No. 68 & 69, 4th Floor, Jubilee Heights Jubilee Enclave, Madhapur <br></br>
                                        Hyderabad, Telangana, 500081<br></br>
                                        India<br></br>
                                        Tel: +91 40 6630 5000<br></br>
                                        Fax: +91 40 6630 5004<br></br>
                                        {/* <span>Learn More
                                            <svg xmlns="http://www.w3.org/2000/svg" fill='#225085' height="20" width="20"><path d="M9.396 16.062q-.271-.27-.281-.604-.011-.333.26-.604l3.979-3.979H4.208q-.354 0-.614-.26-.261-.261-.261-.615t.261-.615q.26-.26.614-.26h9.146L9.375 5.167q-.271-.271-.26-.615.01-.344.281-.614.271-.271.604-.271.333 0 .604.271l5.438 5.437q.146.125.208.292.062.166.062.333t-.062.323q-.062.156-.208.302l-5.438 5.437q-.271.271-.604.271-.333 0-.604-.271Z" /></svg>
                                        </span> */}
                                    </p>
                                </div>
                                <div className='col-md-4 ourOfficesCol colPaddingMiddle'>
                                    <h4>Vizag</h4>
                                    <p>
                                        Plot No.47/P, Sy. No.141<br></br>
                                        APIIC Indl Park, Anandapuram Mandal Gambheeram Village,Visakhapatnam, 531163 India<br></br>
                                        Tel: +91 891 2850000<br></br>
                                        Fax: +91 891 2850004<br></br>
                                        {/* <span>Learn More
                                            <svg xmlns="http://www.w3.org/2000/svg" fill='#225085' height="20" width="20"><path d="M9.396 16.062q-.271-.27-.281-.604-.011-.333.26-.604l3.979-3.979H4.208q-.354 0-.614-.26-.261-.261-.261-.615t.261-.615q.26-.26.614-.26h9.146L9.375 5.167q-.271-.271-.26-.615.01-.344.281-.614.271-.271.604-.271.333 0 .604.271l5.438 5.437q.146.125.208.292.062.166.062.333t-.062.323q-.062.156-.208.302l-5.438 5.437q-.271.271-.604.271-.333 0-.604-.271Z" /></svg>
                                        </span> */}
                                    </p>
                                </div>
                                <div className='col-md-4 ourOfficesCol colPaddingRight'>
                                    <h4>Opening Shortly</h4>
                                    <p >
                                    New state of the art manufacturing facilities coming up in a 4 acre campus in E-City, Hyderabad. A new corporate office is also coming up in Vasavi GP Water Front, Wipro Circle, Financial District, Hyderabad.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section className='ourTeamSection'>
                        <div className='ourTeamDiv'>
                            <h2>Our Team</h2>
                        </div>
                        <div className='ourTeamDiv1'>
                            <div className='row'>
                                <div className='col-md-4 p-0'>
                                    <h4>Board of Directors</h4>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol colStart'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/10.jpg'})`
                                    }}></div>
                                    <p>FOUNDER & CEO</p>
                                    <h5>Dr. Vidyasagar Abburi</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/2.jpg'})`
                                    }}></div>
                                    <p>DIRECTOR</p>
                                    <h5>Sarada Abburi</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/11.jpg'})`
                                    }}></div>
                                    <p>DIRECTOR</p>
                                    <h5>Siddhartha Abburi</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol colEnd'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/YKishoreSirDirector.jpg'})`
                                    }}></div>
                                    <p>DIRECTOR</p>
                                    <h5>Yalamanchili Kishore</h5>
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol colStart'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/NaveenNandigamSirDirector.jpg'})`
                                    }}></div>
                                    <p>DIRECTOR</p>
                                    <h5>Naveen Nandigam</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/RamanGuptaSirDirector.jpg'})`
                                    }}></div>
                                    <p>DIRECTOR</p>
                                    <h5>Ramana Gupta</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/MNarayanaRaoSirDirector.jpg'})`
                                    }}></div>
                                    <p>DIRECTOR</p>
                                    <h5>Narayana Rao</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol colEnd'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/VRamchanderSirDirector.jpg'})`
                                    }}></div>
                                    <p>DIRECTOR</p>
                                    <h5>V Ramchander</h5>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-md-4 p-0'>
                                    <h4>Senior Executives</h4>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol colStart'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/BhaskarSir.jpg'})`
                                    }}></div>
                                    <p>Vice President (Marketing)</p>
                                    <h5>P Balabhaskar Rao</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/NSrinivasaRaosirVICEPRESIDNETTECHNICAL.jpg'})`
                                    }}></div>
                                    <p>Vice Presidnet (Technical)</p>
                                    <h5>N Srinivasa Rao</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/Prakashsir.jpg'})`
                                    }}></div>
                                    <p>Vice President (Strategy & BD)</p>
                                    <h5>Wg Cdr PRL Prakash</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol colEnd'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/GpCaptPSrinivas.jpg'})`
                                    }}></div>
                                    <p>Vice President (Operations)</p>
                                    <h5>Gp Capt P Srinivas (Retd)</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol colStart'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/Kumarsir.jpg'})`
                                    }}></div>
                                    <p>General Manager (Materials)</p>
                                    <h5>BVSB Kumar</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/TNRaosir.jpg'})`
                                    }}></div>
                                    <p>General Manager (QA)</p>
                                    <h5>TN Rao</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/PSrinivasRaoSirGMProduction.jpg'})`
                                    }}></div>
                                    <p>General Manager (Production)</p>
                                    <h5>P Srinivas Rao</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol colEnd'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/PSrinivasSirGMfinance.jpg'})`
                                    }}></div>
                                    <p>General Manager (Finance)</p>
                                    <h5>P Srinivas</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol colStart'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/CDRRSDixitSirGMCustomerSupportjpg.jpg'})`
                                    }}></div>
                                    <p>General Manager (Customer Support)</p>
                                    <h5>CDR RS Dixit</h5>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 teamCol'>
                                    <div className='teamMemberImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/team/MJSKishoreSirGMSoftware.jpg'})`
                                    }}></div>
                                    <p>General Manager (Software)</p>
                                    <h5>MJS Kishore</h5>
                                </div>
                            </div>
                        </div>
                    </section> */}

                </main>
                <Footer />
            </div>
        )
    }
}

export default About