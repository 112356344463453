import React, { Component } from 'react'

export class Navbar extends Component {
    render() {
        return (
            <header id="header" className={`fixed-top header ${this.props.page != 'home' ? 'headerBorder' : ''}`}>
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <div className="container-fluid p-0">
                        <a className="navbar-brand" href="/"><img src='assets/avantel-logo.png' /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${this.props.page === 'about' ? 'active' : ''}`} href="about">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.props.page === 'capabilities' ? 'active' : ''}`} href="capabilities">Capabilities</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.props.page === 'investors' ? 'active' : ''}`} href="investors">Investors</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.props.page === 'news' ? 'active' : ''}`} href="news">News</a>
                                </li>
                               
                                <li className="nav-item">
                                    <a className={`nav-link ${this.props.page === 'contact' ? 'active' : ''}`} href="contact">Contact</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.props.page === 'careers' ? 'active' : ''}`} href="careers">Careers</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        )
    }
}

export default Navbar