import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class Aerospace extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <Navbar />
                <main className="mainContent2">
                    <section className="whatWeDoSubpageSection">
                        <div className="whatWeDoSubpageSectionHeading">
                            <a href='/'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#225085' height="20" width="20"><path d="m9.042 16.042-5.438-5.417q-.146-.146-.208-.302-.063-.156-.063-.323t.063-.323q.062-.156.208-.302l5.438-5.437q.25-.25.604-.261.354-.01.625.261.25.25.25.614 0 .365-.25.615L6.312 9.125h9.126q.354 0 .614.26.26.261.26.615t-.26.615q-.26.26-.614.26H6.312l3.959 3.958q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25Z" /></svg>
                                Back</a>
                            {/* <h2>Aerospace & Defence</h2> */}
                        </div>
                        <div className='whatWeDoSubpageSectionContent'>
                            <div className='aerospaceBanner'></div>
                            <p>
                                Since inception, Avantel customer base traditionally has been the Aerospace and Defence sector and the company is well known for its innovative & indigenous solutions. Avantel has a unique business model wherein it offers an exclusive product mix consisting of niche and state of the art products catering to the emerging and specialized needs of the Indian Defence Forces & allied establishments. Avantel has been certified for the very latest standards viz. ISO 9001:2015 and AS9100D:2016. Avantel products meet various Mil Standards viz. 461 E/F (EMI/EMC), JSS 55555 (Environmental) and SQA compliances viz. IEEE 12207, AS9115, DO 178 and DO 254 as required by the customer. Avantel is the first company in India to offer Airborne SATCOM solutions. The MSS and UHF Satcom systems are currently operating on Boeing P8I, IL-38, DO-228 Aircraft and are shortly being installed on KA – 28 & MH 60 R Helicopters. Avantel has been recognized by CEMILAC (Centre for Military Air Worthiness & Certification) for Design & Development of Airborne products and certified to undertake installations of Avionic equipment on Military Aircraft & Helicopters.
                                <br /><br />
                                The company currently offers wide array of products / solutions in the Aerospace and Defence domain and mainly operates in the following technology areas:
                                <br /><br />
                                <ul>
                                    <li><a href='capabilities#softwareDefinedRadios'>Software Defined Radios</a></li>
                                    <li><a href='capabilities#satcom'>Satcom Technologies</a></li>
                                    <li><a href='capabilities'>Radar Systems</a></li>
                                    <li><a href='capabilities'>HF Communications</a></li>
                                    <li><a href='capabilities'>Embedded Systems</a></li>
                                    <li><a href='capabilities'>Network Management Systems &amp; Application Software Development</a></li>
                                </ul>
                            </p>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        )
    }
}

export default Aerospace