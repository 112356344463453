import React, { Component } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    componentDidMount() { }

    render() {
        return (
            <div>
                <Navbar page="home" />
                <main className="mainContent">
                    <div className="header-carousel">
                        <div id="headerCarousel" className="carousel carousel-dark slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#headerCarousel" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#headerCarousel" data-bs-slide-to={1} aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#headerCarousel" data-bs-slide-to={2} aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className='carouselImgDiv1'>
                                        <img src="assets/home/Homepage banner1_1920x650px-01New.jpg" width={"100%"} alt="homescreen" />
                                        <a className="btn headerVideoBtn" onClick={() => this.setState({ isOpen: true})}>
                                            <img src="assets/playIcon.svg" className="mr-2"  />Watch Now</a>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className='carouselImgDiv'>
                                        <img src="assets/home/Homepage banner2_1920x650px-01.jpg" width={"100%"} alt="homescreen" />
                                        <a className="btn headerVideoBtn headerVideoBtnPosition" onClick={() => this.setState({ isOpen: true})}>
                                            <img src="assets/playIcon.svg" className="mr-2" />Watch Now</a>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className='carouselImgDiv'>
                                        <img src="assets/home/Homepage banner3_1920x650px-01.jpg" width={"100%"} alt="homescreen" />
                                        <a className="btn headerVideoBtn headerVideoBtnPosition1" onClick={() => this.setState({ isOpen: true})}>
                                            <img src="assets/playIcon.svg" className="mr-2" />Watch Now</a>
                                    </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#headerCarousel" data-bs-slide="prev">
                                <img className='home-page-prev-next-icon' src='assets/home/previous.svg' />
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#headerCarousel" data-bs-slide="next">
                                <img className='home-page-prev-next-icon' src='assets/home/next.svg' />
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <ModalVideo channel='custom' autoplay isOpen={this.state.isOpen} url="assets/videos/avantel_video_sep_06_2023.mp4" onClose={() => this.setState({isOpen: false})} />
                    {/* <header className="masthead">
                            <div className="row justifyContent align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-8 col-8 text-center mastheadCol">
                                    <h1>Technology to make the<br /> world better & secure</h1>
                                    <button className="btn"><img className="playIcon" src="assets/playIcon.svg" alt="" />WATCH OUR VIDEO</button>
                                </div>
                            </div>
                    </header> */}
                    <div className="subContent">
                        <section className="whatWeDoSection">
                            <div className="whatWeDoSectionHeading">
                                <h2>What we do</h2>
                                <p>“Avantel offers innovative, customized network centric solutions through products that comply with MIL standards for defence platforms including Ships / Submarines / Aircraft / Helicopters”.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 colStart">
                                    <img className="img-fluid" src="assets/whatWeDo/1-1.jpg" alt="" onClick={() => { this.props.history.push('aerospace') }} />
                                    <h4 onClick={() => { this.props.history.push('aerospace') }}>Aerospace and Defence</h4>
                                    <p>Providing Indigenous solutions in the Aerospace and Defence Electronics domain for making India “Self Reliant”</p>
                                </div>
                                <div className="col-md-6 col-sm-12 colEnd">
                                    <img className="img-fluid" src="assets/whatWeDo/2-1.jpg" alt="" onClick={() => { this.props.history.push('marineCommunications') }} />
                                    <h4 onClick={() => { this.props.history.push('marineCommunications') }}>Marine Communications</h4>
                                    <p>Facilitate highly secured, seamless,  long range communications over satellite and the HF medium for net centric operations of Defence Forces on the main land & high seas</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 colStart">
                                    <img className="img-fluid" src="assets/whatWeDo/3-1.jpg" alt="" onClick={() => { this.props.history.push('customSolutions') }} />
                                    <h4 onClick={() => { this.props.history.push('customSolutions') }}>Custom Innovative Solutions</h4>
                                    <p>Avantel’s R & D is a centre of excellence for Design & Development of innovative, first of its kind and globally competitive products / solutions meeting the special requirements of Strategic Users.</p>
                                </div>
                                <div className="col-md-6 col-sm-12 colEnd">
                                    <img className="img-fluid" src="assets/whatWeDo/4-1.jpg" alt="" onClick={() => { window.open("https://imedsglobal.com/", "_blank") }} />
                                    <h4 onClick={() => { window.open("https://imedsglobal.com/", "_blank") }}>Healthcare</h4>
                                    <p>Providing affordable homegrown healthcare solutions to the needy through Avantel subsidiary “iMeds Global Solutions Private Limited”</p>
                                </div>
                            </div>
                        </section> 
                        <section className="clientsSection">
                            <div className="clientsSectionHeading">
                                <h2>Join our renowned clientele, let us make a difference</h2>
                                <p>We are committed to deliver end to end solutions in our area of expertise always exceeding customer expectations at every instance of our engagement with the customer.</p>
                                <a className="btn custom-primary" href="capabilities">SEE WHAT WE CAN DO FOR YOU</a>
                            </div>
                            <div className="clientIcons">
                                <div className="row iconsRow1">
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/1.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/2.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/3.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/4.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/5.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/6.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/7.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/8.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/9.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/10.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/11.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/12.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/13.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/14.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/15.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/16.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/17.png" alt="" />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-3 d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src="assets/clients/18.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer />
            </div>
        )
    }
}

export default Home;