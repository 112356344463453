import React from 'react';

const NotFoundPage = () => {
  return (
    <div class="notfoundmain">
      <div class="notfounddiv">Oops! We Can't Find That Page</div>
      <p class="notfoundtxt">The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFoundPage;
