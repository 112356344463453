import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class TermsOfUse extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <main className="mainContent1">
                    <h2>Terms of Use</h2>
                    <br></br>
                    <p>By accessing and using the services and information provided by Avantel Limited (hereinafter referred to as the Company™ or a Company ™ on its website www.avantel.in, you hereby understand and agree to all the terms and conditions and disclaimer mentioned hereunder:</p>

                    <p>While the Company has made great efforts to include accurate and up-to date information and links, such information and links on this website are provided as a convenience to you. The content provided/displayed on this website is only for general purposes and whilst the Company does try to ensure the accuracy of such content while incorporating it into this website, such content should not be relied upon for any specific purpose and no representation or warranty, or guarantee is given as regards its accuracy, merchantability or fitness for particular purpose or completeness.</p>

                    <p>The Company may change this website at any time without notice but does not assume any responsibility to update it. All users agree that all access and use of this website and on any website linked to from this site and the content thereof is at their own risk.</p>

                    <p>All contents on the Site are the exclusive property of the Company except the third-party content. The software, text, images, graphics, video and audio used on the Site belong to or are licensed to the Company or its Subsidiaries or its Affiliates. No material from the Site may be copied, modified, reproduced, republished, uploaded, transmitted, posted or distributed in any form without prior written permission from the Company. All rights not expressly granted herein are reserved. Unauthorised use of the materials appearing on the Site may violate copyright, trademark and other applicable laws, and could result in criminal or civil penalties. You are granted a non-exclusive, non-transferable, limited right to access the Site and avail the services provided by the Company on the Site.</p>

                    <p>The Company does not guarantee that the Site will operate error-free or that the Site and its servers are free of computer viruses or other harmful mechanisms. If your use of the Site or content therein results in the need for servicing or replacing equipment or data, the Company is not responsible. The Company does not guarantee about the accuracy, reliability, completeness, or timeliness of the content, services, software, text, graphics, and links.</p>

                    <p>In no event shall the Company be liable for damages of any kind, including without limitation, direct, incidental or consequential damages (including, but not limited to, damages for lost profits, business interruption and loss of programs or information) arising out of the use of or inability to use Site, or any information provided thereon, or any claim attributable to errors, omissions or other inaccuracies in the Site.</p>

                    <p>Our website may use cookies. By using our website and agreeing to these terms of use, your consent to our use of cookies in accordance with the terms of our privacy policy.</p>

                    <p>You shall not post or otherwise transmit, material or messages that contain virus, or any other files that have the possibility to destroy the website and/or the information provided in the website.</p>

                    <p>You shall not use our website to post unsolicited commercial communications.</p>

                    <p>Any information provided by the User will not be shared with any third party, except in the manner provided under these Terms of Use and for the intended purpose for which such information is provided by the User, in which case User's consent is implied.</p>

                    <p>As a matter of convenience to you, the Company may provide links to various third-party websites on the Website. If you use any of these external websites, you may be directed/redirected to such external website and may leave the Website. If you decide to visit any such external link, you agree to do so at your own risk, responsibility and liability. The Company makes no warranty or representation regarding, and does not endorse, any website linked to the Website or the information appearing thereon or any of the products or services described thereon. The Company does not endorse or represent any service provider listed on the Website.</p>

                    <p>You specifically agree that the Company shall not be responsible for unauthorized access to or alteration of your transmissions or data, any material or data sent or received or not sent or received through the Site.</p>

                    <p>As a condition of Your use of the Website, You shall not use the Website for any purpose(s) that is unlawful or prohibited by the Terms of Use. You shall not use the Website in any manner that could damage, disable, overburden, or impair any of the Company's server, or the network(s) connected to any of the Company's server, or interfere with any other party's use and enjoyment of any services associated with the Website. You shall not attempt to gain unauthorized access to any section of the Website, other accounts, computer systems or networks connected to any of the Company's server or to any of the services associated with the Website, through hacking, password mining or any other means. You shall not obtain or attempt to obtain any Materials or information through any means not intentionally made available through the Website.</p>

                    <p>The Company reserves the right in its sole discretion to investigate and take legal action against anyone who engages in any illegal or prohibited conduct or otherwise violates these Terms of Use, including without limitation, removing the User content from the Site and/or terminating the offending User's ability to access the Site and/or use services therein.</p>

                    <br />
                    <h4>Indemnity</h4>

                    <p>You agree to defend, hold harmless and indemnify the Company, its directors and officers from and against any and all losses, costs, expenses, damages or other liabilities incurred by the Company and/or its Directors and officers, from and against any cost, liability, loss, damage, cause of action, claim, suit, proceeding, demand or action brought by a third party against the Company and/or its directors and officers, due to or arising out of or in connection with Your use of the Website or Content or Your breach of any provision of the Terms, or any negligent or intentional wrongdoing on Your part.</p>

                    <br />
                    <h4>Information Technology Act, 2000</h4>

                    <p>The User hereby specifically agrees to comply with the requirements of the Information Technology Act, 2000 as also rules, regulations, guidelines, bye laws, notifications and amendments made thereunder, while on the Site. The user also undertakes that the content provided by him/her is not defamatory, obscene, threatening, abusive or hateful. The User shall alone be responsible for all his acts, deeds and things and that he/she alone shall be liable for civil and criminal liability there under or under any other law for the time being in force.</p>

                    <br />
                    <h4>Jurisdiction</h4>

                    <p>The Terms of Use shall be governed by the Laws of India. The Courts of law at Hyderabad, Telangana State, India shall have exclusive jurisdiction over any disputes arising under the Terms of Use.</p>

                </main>
                <Footer />
            </div>
        )
    }
}

export default TermsOfUse