import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class Nabhmitraprivacypolicy extends Component {
  render() {
  return (
    <div>
        <main className="mainContent1" style={{marginTop:"1rem"}}>
            <h2>Privacy Policy</h2>
            <br></br>
            <h4>1. Introduction</h4>
            <p>Welcome to Nabhmitra ("we," "our," "us"). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information when you use our fishing boat tracking application ("App").</p>

            <h4>Information We Collect</h4>
            <p>Our App does not collect any personal information such as credit card details, personal identification, or registration information. The App connects to an external device (Xponder) via WiFi to retrieve position data. Additionally, the App supports messaging functionalities. This data will be used by Department of Fisheries (DoF), Govt of India. Here’s how we handle the information:
              <ul>
                <li><b>Position Data:</b> The App retrieves latitude and longitude coordinates from the connected external unit. This data is necessary for the functionality of the App and is transmitted via satellite communication to the Control Center (DoF).
                </li>
                <li>
                  <b>Messages:</b>The App allows for the exchange of text messages and emergency notifications between boats and the Control Center (DoF). All messages are transmitted through a satellite link.
                </li>
              </ul>
            </p>

            <h4>Use of Information</h4>
            <p>The information handled by our App is used as follows:</p>
            <ul>
              <li><b>Position Data:</b> Collected from external device (not from the mobile GPS) Used to track the location of fishing boats and to transmit this data to the designated Control Center (DoF) to support the core functionality of the App.</li>
              <li><b>Messages:</b>Used to facilitate communication between boats and the Control Center (DoF), including sending and receiving text messages and emergency notifications.</li>
            </ul>

            <h4>Data Sharing and Disclosure</h4>
            <p>We do not share, sell, or disclose your data or messages to third parties. </p>

            <h4>Data Security</h4>
            <p>We implement reasonable technical and organizational measures to safeguard the position data and messages transmitted by the App. However, please be aware that no data transmission over the Internet or electronic storage is completely secure.</p>

            <h4>Data Retention</h4>
            <p>We retain position data and messages only for as long as necessary to fulfill the purposes outlined in this Privacy Policy and to comply with applicable legal requirements.</p>

            <h4>Changes to This Privacy Policy</h4>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the App after any changes signifies your acceptance of the updated policy.</p>

            <h4>Misuse by Non Targeted Users</h4>
            <p>All mobile apps are meant for use by the targeted audience only. Misuse by non-targeted users should be prevented by owner of the mobile.</p>
            <h4>Contact Us</h4>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:<br></br>
              <b>Department of Fisheries (DoF) Address – Action by NSIL<br></br>

Chanderlok Building, Ground Floor<br></br>
36, Janpath, New Delhi-110 001<br></br>
</b>
            </p>
          </main>
    </div>
  )
}
}
 
export default Nabhmitraprivacypolicy
