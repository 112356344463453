import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <main className="mainContent1">
            <h2>Privacy Policy</h2>
            <br></br>
            <p>Modern information and communication technologies play an ever increasing role in the activities of organizations. Protecting your privacy while processing your personal data is a key concern to which we give due regard in all business processes. Personal data is collected, processed and used solely in conformity with the Data Protection Act.</p>
            
            <p>Please note that the website of Avantel Limited may contain links to websites from other providers to which this privacy policy does not extend.</p>
            
            <p>We do not record your personal data when you view our website. The only information recorded comprises the IP address currently assigned to your computer, the date and time, the browser type and operating system used on your computer, and the pages you view. It is not possible to infer your identity from this data. The data automatically recorded in log files when you view our website is logged solely for statistical and security-related purposes.</p>
            
            <p>Other personal data such as name, address, telephone number or email address is needed solely if you contact us directly using our contact details. In such cases, the requisite data is collected with your consent, and you can decide personally what information you wish to provide to us beyond what is required as part of our contractual relationship.</p>
            
            <p>On request, we will be pleased to provide you with written notification stating which items of your personal data, if any, we have on file.</p>
            <br></br>
            <h4>Cookies</h4>
            
            <p>We use cookies in some areas of our websites. These are data pads which enable us to make our services available to you on a more individual basis during your visit. We do not use cookies to gather personal data. If you would like to be informed about the use of cookies on your browser or if you would like to rule out their use, you should activate the appropriate browser settings.</p>
            <br></br>
            <h4>Security</h4>
            
            <p>We have taken technical and organisational steps to protect the personal data which you provide against loss, destruction, manipulation and unauthorized access.</p>

            <p>We reserve the right to amend this declaration at any time. This declaration shall not justify any contractual or other formal right vis-à-vis or by order of one party.</p>

            <p>If you have any questions or comments on our privacy policy at Avantel Limited, please write to info@avantel.in</p>
            <br></br>
            <h4>Liability for content</h4>

            <p>The content on our website has been compiled with the greatest of care. We cannot, however, guarantee that the content is correct, complete or up to date. We are not required to monitor third party information transmitted or stored or to search for circumstances indicating illegal activity. This does not prejudice any duty under general legislation to remove or block the use of information. No liability can be owed in this regard, however, until such time as we have knowledge of a specific breach of the law. If any such breach of law should become known, we will remove the content without delay.</p>
            <br></br>
            <h4>Copyright</h4>

            <p>The existing contents of the Avantel Limited internet presentation and of its subsidiaries are protected by copyright. All rights reserved. Except files specifically offered for downloading (for use without modification), the use of the texts and illustrations, even excerpts from them, without the written consent of Avantel Limited or its subsidiaries is a violation of the provisions of copyright law and is therefore illegal. This applies particularly to their reproduction, translation or use in electronic systems. Trademarks, trade names and other distinguishing marks are used on the Internet sites. Even if these are not labelled as such, the appropriate legal stipulations shall apply.</p>
            <br></br>
            <h4>Disclaimer</h4>

            <p>The information on this website is purely for the purpose of presenting Avantel Limited and its products and services. It is provided on an "as is" basis and without warranty of completeness, correctness or any other kind, expressed or implied, including (but not limited to) any implied warranties of merchantability, fitness for any particular purpose, or non-infringement of third-party rights. Avantel Limited recommends verifying any information obtained from this website prior to its use.</p>

            <p>Users of this website agree to access the website and its content at their own risk. Avantel Limited shall not be liable for any damages incurred in connection with this website.</p>
            <br></br>
        </main>
        <Footer />
      </div>
    )
  }
}

export default PrivacyPolicy