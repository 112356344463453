import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabName: 'all'
        }
    }
    render() {
        return (
            <div>
                <Navbar page="news" />
                <main className="mainContent1">
                    <section className="newsSection">
                        <div className="newsSectionHeading">
                            <div className='newsBanner'></div>
                        </div>
                        {/* <div className='newsTabs'>
                            <button className={`btn ${this.state.tabName === 'all' ? 'custom-primary1' : 'custom-outline-primary'} `} onClick={() => { this.setState({ tabName: 'all' }) }}>ALL</button>
                            <button className={`btn ${this.state.tabName === 'news' ? 'custom-primary1' : 'custom-outline-primary'} `} onClick={() => { this.setState({ tabName: 'news' }) }}>NEWS</button>
                            <button className={`btn ${this.state.tabName === 'events' ? 'custom-primary1' : 'custom-outline-primary'} `} onClick={() => { this.setState({ tabName: 'events' }) }}>EVENTS</button>
                        </div> */}
                    </section>
                    <section className="newsTabSection">
                        <div className='newsDiv'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <p className='newsSubTitle'>IN THE NEWS</p>
                                    <h5 className='newsTitle'>National CSR Award</h5>
                                    <p className='newsDesc'>Avantel has won the National CSR Award 2020 for CSR Awards for CSR in
                                        Aspirational Districts / Difficult Terrains - Southern India under MSME winner
                                        category</p>
                                    <a className='newlink' href='https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1852885' target={'_blank'}>https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1852885</a>
                                    <p className='newsDate'>18 August 2022</p>
                                </div>
                                <div className='col-md-4 newsColRight'>
                                    <div className='newsBgImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/news/4.jpg'})`
                                    }}></div>
                                </div>
                            </div>

                          {/* <a className='popup-vimeo avlvideo' href='https://player.vimeo.com/video/954340570?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' >34<sup>th</sup> AGM</a> */}





                          <div className='row' style={{marginTop: 100}}>
                                <div className='col-md-8'>
                                    <h5 className='newsTitle'>Avantel Ltd. Is Now Listed On The National Stock Exchange (NSE)! </h5>
                                    <p className='newsDesc'>Avantel Ltd. has achieved a remarkable milestone by being listed on the National Stock Exchange (NSE), marking a new chapter in our history.</p>
                                    <p className='newsDesc'>For over three decades, Avantel Ltd. has been a pioneer in strategic electronics and satellite communication systems, dedicated to advancing India’s aerospace and space capabilities with innovative solutions. Our steadfast commitment to the #AtmanirbharBharat initiative and the indigenization of technology underscores our role in supporting the nation’s strategic imperatives.</p>
                                    </div>
                                    <div className='col-md-4 newsColRight'>
                                    <div className='newsBgImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/news/avl_nse.jpg'})`
                                    }}></div>
                                     </div>
                            </div>

                            <div className='row' style={{marginTop: 50}}>
                                <div className='col-md-8'>
                                    <p className='newsDesc'>Being listed on the #NSE, in addition to our presence on the BSE, reinforces our dedication to greater accessibility and transparency for our investors.</p>
                                    <p className='newsDesc'>We extend our heartfelt gratitude to our shareholders, partners, and the entire Avantel family for their unwavering commitment and hard work. This achievement is a shared success, and we look forward to continuing this journey together.</p>
                                 </div>

                                    <div className='col-md-4 newsColRight'>
                                    <div className='newsBgImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/news/avl_nse_2.jpg'})`
                                    }}></div>
                                     </div>
                               
                            </div>




                            <div className='row' style={{marginTop: 100}}>
                                <div className='col-md-12'>
                                    <h5 className='newsTitle'>Avantel's Strategic Collaboration with INSPACe: Pioneering India's Space Capabilities</h5>
                                </div>
                            </div>
                            <div className='row' style={{marginTop: 40}}>
                                <div className='col-md-8'>
                                    <p className='newsDesc'>Avantel, a leader in strategic electronics and satellite communication systems, continues to excel in providing innovative solutions for India's defence and commercial sectors. With over three decades of experience, Avantel has been at the forefront of indigenization and the Atmanirbhar Bharat initiative, delivering turnkey solutions that meet the country's strategic needs.</p>
                                    <p className='newsDesc'>In a significant move, Avantel is setting up a Ground Segment as a Service (GSaaS) Facility, which includes an Antenna Farm supporting various frequency bands. This facility will cater to commercial needs across VHF, UHF, S, C, X, and Ka bands, and will feature advanced IT infrastructure, data acquisition support, and high-speed network integration for seamless operations.</p>
                                    
                                </div>
                                <div className='col-md-4 newsColRight'>
                                    <div className='newsBgImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/news/avl_insl.jpg'})`
                                    }}></div>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12'>
                                   

                                    <p className='newsDesc'>Avantel has received authorization from the Indian National Space Promotion and Authorisation Centre (INSPACe) to establish and operate multiple Ground Stations. These stations, both fixed and transportable, will support TT&C, Payload data reception, and Ranging operations. This authorization strengthens Avantel's position as a key player in the Indian Space Segment.</p>

                                    <p className='newsDesc'>Avantel is spearheading GSaaS services in India. This initiative will provide critical ground support for real-time satellite communication and control, reducing financial barriers for startups and accelerating their R&D efforts. The aim is to position India as a hub for GSaaS services, supporting global satellite constellations and promoting India as a leading space technology destination.</p>
                                    <p className='newsDesc'>With these advancements, Avantel continues to drive innovation and excellence in space technologies, contributing significantly to India's self-reliance and global stature in the space industry.</p>
                                </div>
                            </div>


                            <div className='row' style={{marginTop: 100}}>
                                <div className='col-md-12'>
                                    <h5 className='newsTitle'>Avantel Shines at India Space Congress: Panel Discussion on Smart Satellites</h5>
                                </div>
                            </div>

                            <div className='row' style={{marginTop: 40}}>
                                <div className='col-md-8'>
                                    <p className='newsDesc'>Avantel’s Director Mr. Siddhartha Abburi  participated in the prestigious India Space Congress at The Lalit, New Delhi. As a key speaker on the panel discussing 'Unlocking the Potential of Smart Satellites,' he delved into the future of space technology, focusing on Next Gen Connectivity and how smart & flexible systems are revolutionizing satellite capabilities.</p>
                                    <p className='newsDesc'>Dedicated to pioneering adaptable solutions and innovative SATCOM technologies, Avantel's commitment to Made in India excellence ensures it leads the charge in advancing space technology globally. These innovations are enhancing connectivity, bolstering national security, and poised to make significant impacts across industries and societies.</p>

                                </div>
                                <div className='col-md-4 newsColRight'>
                                    <div className='newsBgImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/news/avl_satilite.jpg'})`,
                                        height:'290px', 
                                    }}></div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    

                                    <p className='newsDesc'>Engaging in insightful discussions with fellow experts and stakeholders, Avantel showcased its dedication to harnessing the full potential of Space Technology. The event emphasized collaboration to shape a future where innovation knows no bounds, positioning India as a beacon of technological prowess.</p>
                                </div>
                            </div>

                            <div className='row' style={{marginTop: 100}}>
                                <div className='col-md-12'>
                                    <h5 className='newsTitle'>Avantel's MD Discuss the Strategic Journey and Vision for the Future! </h5>
                                    <p className='newsDesc'>
                                    In a compelling speech given on the occasion of Avantel's listing on the National Stock Exchange (NSE), Avantel's Managing Director Mr. Vidya sagar abburi highlights the transformative role of technology in today's world. He delves into the three significant approaches that have defined Avantel's success: incremental innovation, constantly evolving and improving our technologies to stay ahead; critical technologies, delivering unique, customized solutions for the defense sector; and flexible and scalable architecture, ensuring our systems can adapt and grow with changing needs. He also shares personal insights into his journey, inspired by two impactful books: "Build to Last" and "Blue Ocean Strategy." Remarkably, he was already following these strategies even before discovering the books. For him, the journey is not just about success but about fulfillment. He emphasizes that an institution must continue to thrive beyond the presence of its founders, and this belief is deeply ingrained in Avantel's work culture. Join us in celebrating this inspiring journey and learn how Avantel continues to pioneer innovations and foster a fulfilling work environment
                                    </p>

                                  
                                </div>
                            </div>


                            
                            <div className='row' style={{marginTop: 100}}>
                                <div className='col-md-12'>
                                    <h5 className='newsTitle'>Shaping the Future: Avantel Connects Academic Excellence with Industry Innovation </h5>
                                </div>
                            </div>

                            <div className='row' style={{marginTop: 40}}>
                                <div className='col-md-8'>
                                    <p className='newsDesc'>At Avantel, we pride ourselves on being at the forefront of technological innovation, specializing in advanced communication systems and solutions that empower industries ranging from defence to telecommunications. With over three decades of expertise, we’ve consistently driven progress by integrating cutting-edge technology with practical application. In line with this vision, we are proud to collaborate with Andhra University to launch a transformative MTech program in Signal Processing and Communication Systems.
</p>
                                    <p className='newsDesc'>This initiative is designed to seamlessly connect academic excellence with real-world industry demands, creating a blueprint for how education and enterprise can shape the leaders of tomorrow.</p>
                                 

                                </div>
                                <div className='col-md-4 newsColRight'>
                                    <div className='newsBgImg' style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/news/avl-andhrauniversity.jpg'})`,
                                        height:'290px', 
                                    }}></div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    
                                    <p className='newsDesc'>Here’s how we’re making an impact:</p>
                                    <p className='newsDesc'><b>- Hands-On Experience:</b> Students will gain valuable real-world exposure through a one-year paid internship, during their third and fourth semesters.<br></br>
<b>- Career Readiness:</b> With a curriculum designed to meet industry demands, students graduate ready to excel in cutting-edge roles.<br></br>
<b>- Assured Opportunities:</b> Successful graduates will join the workforce of Avantel, paving the way for a bright future.
</p>
<p className='newsDesc'>This initiative exemplifies Avantel’s commitment to empowering the next generation of innovators by creating meaningful pathways from classroom to career. Together, we’re shaping a future where education and industry work hand in hand to drive progress.<br/>Read more about this collaboration here: <a href="https://lnkd.in/g4wSH-v8" target={'_blank'}>https://lnkd.in/g4wSH-v8</a><br/></p>
                                </div>
                            </div>



                        </div>
                        {/* <div className='newsDiv'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <p className='newsSubTitle'>IN THE NEWS</p>
                                    <h5 className='newsTitle'>New infrastructure plant Sunt qui esse pariatur duis deserunt cillum minio. </h5>
                                    <p className='newsDesc'>Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                    <p className='newsDate'>July, 12 2022</p>
                                </div>
                                <div className='col-md-4 newsColRight'>
                                    <div className='newsBgImg' style={{ 
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/news/2.png'})`  }}></div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='conferenceDiv'>
                            <div className='row'>
                                <div className='col-md-2 d-flex align-items-center justify-content-center'>
                                    <div>
                                        <p className='conferenceMonth'>JULY</p>
                                        <p className='conferenceDate'>17th</p>
                                    </div>
                                </div>
                                <div className='col-md-10'>
                                    <p className='conferenceSubTitle'>CONFERENCE</p>
                                    <h5 className='conferenceTitle'>Annual general shareholders meeting</h5>
                                    <p className='conferenceDesc'>
                                        July 17th, 2022 at 9:00am IST <br></br>
                                        In person and virtual at address goes here
                                    </p>
                                    <a className='rsvpLink'>RSVP <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='newsDiv'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <p className='newsSubTitle'>IN THE NEWS</p>
                                    <h5 className='newsTitle'>New infrastructure plant Sunt qui esse pariatur duis deserunt cillum minio. </h5>
                                    <p className='newsDesc'>Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                    <p className='newsDate'>July, 12 2022</p>
                                </div>
                                <div className='col-md-4 newsColRight'>
                                    <div className='newsBgImg' style={{ 
                                        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/news/3.png'})`  }}></div>
                                </div>
                            </div>
                        </div>
                        <div className='conferenceDiv'>
                            <div className='row'>
                                <div className='col-md-2 d-flex align-items-center justify-content-center'>
                                    <div>
                                        <p className='conferenceMonth'>JULY</p>
                                        <p className='conferenceDate'>17th</p>
                                    </div>
                                </div>
                                <div className='col-md-10'>
                                    <p className='conferenceSubTitle'>CONFERENCE</p>
                                    <h5 className='conferenceTitle'>Annual general shareholders meeting</h5>
                                    <p className='conferenceDesc'>
                                        July 17th, 2022 at 9:00am IST <br></br>
                                        In person and virtual at address goes here
                                    </p>
                                    <a className='rsvpLink'>RSVP <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div> */}
                    </section>
                    <section className={`newsTabSection ${this.state.tabName === 'news' ? 'd-block' : 'd-none'}`}>
                        <p>news</p>
                    </section>
                    <section className={`newsTabSection ${this.state.tabName === 'events' ? 'd-block' : 'd-none'}`}>
                        <p>events</p>
                    </section>
                </main>
                <Footer />
            </div>
        )
    }
}

export default News