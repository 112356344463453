import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class QualityPolicy extends Component {
    render() {
        return (
            <div>
                <Navbar page="qualityPolicy" />
                <main className="mainContent1">
                    <section className="qualityPolicySection">
                        <div className="qualityPolicySectionHeading">
                            <div className='qualityPolicyBanner'></div>
                        </div>
                    </section>
                    <section>
                        <p className='para1'>
                            To Develop Quality Products through Innovation by Integration of
                            Complementary Technologies in the areas of Electrical Engineering,
                            Communication and Computing.
                        </p>
                        <div className='solutionsDiv'>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        <div className='solutionsBgImgCol'>
                                            <div className='qualityPolicyImg1'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Our Quality Policy and commitment for excellence include:</h5>
                                        <p>
                                            <ul>
                                                <li>To design, operate and maintain a quality system covering all aspects of product designs and processes to identify and meet the customer’s stated and implied requirements in time at competitive price</li>
                                                <li>To bring about continuous improvement in products/processes/systems to achieve excellence through innovation</li>
                                                <li>To achieve the above stated objectives through the commitment and involvement of every individual and to develop attitudes, skills and knowledge</li>
                                                <li>To ensure customer satisfaction and endeavor to exceed customer expectations</li>
                                                <li>To promote environment friendly ideas and technologies in design, engineering and manufacturing practices</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        <div className='solutionsBgImgCol'>
                                            <div className='qualityPolicyImg2'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Avantel Quality Certifications:</h5>
                                        <p>
                                            <ul>
                                                <li>AS9100D and ISO 9001:2015 certified Quality Management System.</li>
                                                <li>ISO 14001:2015 certified Environment Management System</li>
                                                <li>ISO 45001:2018 certified Occupational Health &amp; Safety management system</li>
                                                <li>ISO 27001:20013 certified information security management system ( ISMS )</li>
                                                <li>ISO/IEC 17025:2017 certified Environmental Test Facility with NABL Accreditation.</li>
                                                <li>DGQA Award of Self Certification for Satcom Products.</li>
                                                <li>CEMILAC approval for Design and Development of Products and Software for Airborne Systems</li>
                                                <li>Industrial License by GoI for Design, Development and Manufacture of EW, Radar and Satcom systems</li>
                                                <li>In-house R&amp;D recognized by DSIR, Govt of India.</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        )
    }
}

export default QualityPolicy