import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabName: 'technologies',
            showVideo: false
        }
    }

    componentDidMount() { }

    render() {
        return (
            <div>
                <Navbar page="capabilities" />
                <main className="mainContent1">
                    <section className="servicesSection">
                        <div className="servicesSectionHeading">
                            <h2>What we can do for you</h2>
                            <p>We offer design, development and manufacture of products for C4ISR Solutions.</p>
                        </div>
                        <div className='servicesTabs'>
                            <button className={`btn ${this.state.tabName === 'technologies' ? 'custom-primary1' : 'custom-outline-primary'} `} onClick={() => { this.setState({ tabName: 'technologies' }) }}>TECHNOLOGIES</button>
                            <button className={`btn ${this.state.tabName === 'solutions' ? 'custom-primary1' : 'custom-outline-primary'} `} onClick={() => { this.setState({ tabName: 'solutions' }) }}>SOLUTIONS</button>
                            <button className={`btn ${this.state.tabName === 'infrastructure' ? 'custom-primary1' : 'custom-outline-primary'} `} onClick={() => { this.setState({ tabName: 'infrastructure' }) }}>INFRASTRUCTURE</button>
                        </div>
                    </section>
                    <section className={`servicesTabSection ${this.state.tabName === 'technologies' ? 'd-block' : 'd-none'}`}>
                        <div className='technologiesImg'></div>
                        <p className='para1'>Avantel has over three decades of experience in Design, Development, Manufacturing, System Engineering, Deployment and After Market Support of wide range of defence electronics products. The company offerings are either import substitutes or purely indigenous thus directly contributing towards making India truly self reliant.  Our core competencies include Wireless & Satellite systems, RF System Design, Embedded Systems & Signal Processing, Network Management & Software Development and Engineering & IT Services. The company invests its time and resources to constantly scale & upgrade in emerging technologies as per the needs of its customers.</p>

                        <div className='solutionsDiv'>
                            <div className='solution' id='softwareDefinedRadios'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>Software Defined Radios</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg3'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Software Defined Radios</h5>
                                        <p>
                                            Avantel has designed and developed fully indigenous HF Software Defined Radio (SDR) covering Frequency Range of 1.5 to 30 MHz in compliance to Software Communication Architecture (SCA) 4.1. <br></br><br></br> The HF SDR implements specified transceiver API at the top level and the hardware is controlled/ accessed through intervening SCA core framework. The ORB (Object Request Broker) layers are running on top of a POSIX compliant real time operating system (Petalinux).<br></br><br></br> Avantel has the capability to offer multi frequency SDR solutions for tactical and airborne applications as per customer specific requirements.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution' id='satcom'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>SATCOM</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg4'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Satellite Communications</h5>
                                        <p>
                                            Avantel is at the fore front of innovation in satellite communications. Avantel developed customized solutions for GSAT based Mobile Satellite Services with advanced microwave, digital wireless communications and signal processing products for military and commercial markets. <br></br><br></br>
                                            Our SATCOM Radios operate in ‘S’ and ‘UHF’ band and provides Full-duplex Voice/Data Communications between Ships, between Aircraft, Aircraft - Ship, Ship - Base, Base - Ship etc. We are presently working on solutions in X-Band / Ku-Band as well.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>HF Communications</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg5'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>HF Communications</h5>
                                        <p>
                                            Our HF solutions are a true example of “Make in India” initiative meeting the norms of indigenous content as per “IDDM” category of DAP 2020. <br></br><br></br>
                                            We currently offer 1 KW and 5 KW solutions for Static and Ship borne Applications.  The HF Radio operates in wideband (24 KHz) and consists of the latest state-of-art technology that includes FPGA, DSP, Direct Digital frequency synthesis, Automatic link establishment using 3G ALE (STANAG4538), 2G ALE (FED-STD 1045), Frequency hopping and customized waveform loading capability.<br></br><br></br>
                                            The HF transceivers system covers the 1.6 to 30 MHz frequency range in 1 Hz steps. Communication is possible in both simplex and half-duplex in Single Sideband (SSB), Amplitude Modulation (AM), and Digital modulation. These HF Transceivers provides the interface for high speed data and digital voice encryption using external crypto device.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>Radar Systems</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg6'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Radar Systems</h5>
                                        <p>
                                            Our Radar systems portfolio comprises of Radar subsystems viz. T/R modules, Exciter, DTCSG, MTCSG, Distribution unit etc in the frequency ranges of HF, UHF, L, S, C and X bands. We also offer complete Radar solutions as per customer specifications.
                                            <br></br><br></br>
                                            <ul>
                                                <li>Timing and control generation</li>
                                                <li>Power output upto 7.5KW</li>
                                                <li>Solid state and fast switching.</li>
                                                <li>Active array based</li>
                                                <li>Local and remote operation</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>Network Management Systems & Application Software Development</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg7'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Network Management Systems & Application Software</h5>
                                        <p>
                                            Avantel offers a wide range of services in the area of application development. Avantel can identify the need, design and build the solutions from scratch to suit the specific requirement of the customer. Avantel follows a well defined software engineering processes and established methodologies. Avantel with its past domain and technology expertise will bring in innovative ideas and offer solution to improve the existing functionality, address the current pain areas and technology limitations. The solution offered will be scalable to meet future business growth and make it accessible to external stakeholders. Our software development process is complied as per AS 9115 standard. All airborne software undergoes airworthiness certification process from CEMILAC.
                                            <br></br><br></br>
                                            <ul>
                                                <li>NMS for Mobile Satellite Services (MSS)</li>
                                                <li>UHF DAMA NMS</li>
                                                <li>HF Remote Control Software</li>
                                                <li>MMI for SATCOM Terminals installed on Aircraft, Submarines, Ships & Manpacks</li>
                                                <li>Real Time Train Tracking and Information System</li>
                                                <li>Radar controller</li>
                                                <li>Automated Test Equipment Software</li>
                                                <li>Simulation software</li>
                                                <li>Database generation for airfield simulation</li>
                                                <li>ERP for SME</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>Embedded Systems</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg8'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Embedded Systems & Digital Signal Processing</h5>
                                        <p>
                                            Avantel undertakes full stack solutions for design, development, verification, certification of complex and diverse embedded systems for Communications and Radar applications. We have the latest state of the art tools for undertaking the embedded systems development.
                                            <br></br><br></br>
                                            <ul>
                                                <li>SCA development Environment</li>
                                                <li>Development environment for 8/16/32 bit micro-controllers, AD/TI DSP platforms, FPGAs</li>
                                                <li>Keil, Dynamic C, Silicon Lab, MP Lab, Code Composer Studio, Visual DSP++ for DSP Processors, Xilink Simulators (MATLAB)       </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={`servicesTabSection ${this.state.tabName === 'solutions' ? 'd-block' : 'd-none'}`}>
                        <div className='solutionsImg'></div>
                        <p className='para1'>Avantel unique solutions enhance the operational capabilities of the customers multifold and aimed at solving real-world problems in a comprehensive manner. Avantel key strength lies in close customer association during the product development life cycle. The company  also undertakes continuous product  enhancements in tune with customer expectations / requirements with time.</p>

                        <div className='solutionsDiv'>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>MSS Network</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg9'>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Mobile Satellite Services (MSS) Network</h5>
                                        <p>
                                            Avantel has established the MSS network for Indian Navy for providing reliable, secured, real-time, long range (1000 nautical miles from shore) voice and data communications among Ships, Submarines, Aircraft and Helios. The network comprises of indigenously designed & developed Avantel’s Earth station electronics and platform equipment.  <br></br><br></br>
                                            The network has S X C and C X S links for facilitating uplink and downlink communications. Each of the user terminals specially the aircraft and submarine versions are platform specific and designed as per the specified Mil standards. The NMS and Application software has been developed in house by Avantel as per applicable standards. Avantel is the first company to roll out indigenous Airborne Satcom solutions duly approved by CEMILAC and form part of this network.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg10'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>UHF SATCOM Network</h5>
                                        <p>
                                            The IN UHF network comprises of SATCOM plus LoS terminals for Ship borne applications and Satcom only Airborne terminals and is extensively used for Beyond Line of Sight (BLoS) operations in high Seas.   <br></br><br></br>
                                            The hub electronics and user terminals have been indigenously designed, developed and deployed by Avantel on various IN ships and Aircraft. <br></br><br></br>
                                            These terminals facilitate Full-duplex voice /data communication and work in Demand Assigned Multiple Access (DAMA)/Permanent Assigned Multiple Access (PAMA) modes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg11'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Wind Profiler Radars</h5>
                                        <p>
                                            Avantel developed Wind Profile Radars and deployed at SDLC-SHAR, ISRO (49 MHz) and NARL (30 MHz).  These Wind Profiler Radars provides three dimensional, high resolution wind data from 450 meters to 21 KM altitudes and supports wind velocity of 1m/s to 30m/s. <br></br><br></br>
                                            Wind profilers provide three-dimensional atmospheric wind data on a continuous basis with good spatial resolution. This continuous high-resolution wind data is very useful for studying the development of wind shears in near real time and is extremely crucial before successful satellite launch.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg16'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Real-time Information System for Indian Railways</h5>
                                        <p>
                                            Avantel has developed a unique, real time and cost effective solution for position determination and location transmission of the rolling stock for Indian railways. The Real-time Train Information System (RTIS) helps the railway operator to run the rolling stock control applications automatically. The solution facilitates real time tracking using GPS + GIS + Zigbee + MSS + 4G technologies.
                                            <br></br><br></br>
                                            The system also enables the train information to be accessed through web or mobile service provider. The solution automatically computes the arrival and departure of passenger trains and updates the same through the passenger information system or to an enquiry run by the railway operator.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg12'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Sahayak</h5>
                                        <p>
                                            Our MSS solution has been deployed in the Sahayak project developed by NSTL & ADE for Indian Navy to facilitate the transmission of positional information through the satellite for quick recovery of Sahayak containers in high seas.
                                            <br></br><br></br>
                                            The “Sahayak” air droppable containers are aimed at enhancement of operational logistics of Indian Navy and facilitate quick supply of essential spares and stores to Ships deployed at more than 2000 Kms in high seas. This solution will reduce the requirement of ships coming alongside frequently for replenishments and enhance deployment duration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg13'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>ERM-SAT</h5>
                                        <p>
                                            ERMSAT has been designed for specific needs of strategic customers for Environment Radiation Monitoring. The standalone solar powered Environmental Radiation Monitor can be installed at various remote locations and the radiation data is collected through sensors is transmitted to a centralized location through  satellite communication.
                                            <br></br><br></br>
                                            The ERM-SAT systems are compatible with other mode of communications I.e.  GSM, LAN, ZigBee etc. and these are rugged, compact, energy efficient and incorporate secure (encryption based) data communication.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg14'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Ocean Gauges & Tide Gauge Applications</h5>
                                        <p>
                                            Avantel has developed and supplied MSS and UHF Transmitters to INCOIS and NIoT for integration with the Wave rider/ Drifting Buoys and Tide Gauge equipment.
                                            <br></br><br></br>
                                            Our equipment forms the critical part of the Tsunami early warning systems in India and are used to transfer the sea level data to the control centre 24 X 7 to detect unusual sea conditions and provide early warning for Tsunami occurrence to the administrative authorities and people as part of disaster preparedness and management initiatives.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg15'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Visual Databases for Aircraft Simulators</h5>
                                        <p>
                                            We have extensive experience in building of visual databases for aircraft simulators. We built 12 (virtual) Indian airfields of interest to our customers and each of the airfield has Out-of-The-Window (OTW), Electro Optical (EO), Infra-Red (IR) and RADAR databases for various mission modes.

                                            These airfields are available on round-earth 3D terrain called Whole Earth (covering area between 60 degrees north and 60 degrees south). The efforts for development of these databases involved the following:
                                            <br></br><br></br>
                                            <ul className='mt-2'>
                                                <li>Airfields are built on top of 1m resolution insets and environment features on top of 5m resolution insets. Both 1m&5m insets are blended on top of 15m Whole World Imagery.</li>
                                                <li>DTED 1 data is used for seamless transition for terrain elevation to match with ICAO airfield elevations.</li>
                                                <li>All the airfield data like runways, airfield lighting, taxiways, markings, and signs are through site surveys to ensure correctness and highest accuracy data. </li>
                                                <li>Geo-specific modeling for areas close to airfield for better replication of actual ground conditions. And for other areas, geo-typical models are used.</li>
                                                <li>A Library of moving models including submarines, surface combat ships, aircraft, ground vehicles, etc.</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={`servicesTabSection ${this.state.tabName === 'infrastructure' ? 'd-block' : 'd-none'}`}>
                        {!this.state.showVideo ?
                            <div className='infraImg'>
                                {/* <button className='video-btn' onClick={() => this.setState({ showVideo: true })}><img src='../assets/playIcon.svg' /></button> */}
                            </div>
                            :
                            <video autoPlay muted loop playsInline preload="auto" className="video">
                                <source src="../assets/videos/Avantel Infrastructure.mp4" type="video/mp4" />
                            </video>
                        }
                        <p className='para1'>
                            Avantel,a technology driven company offers wireless, Satcom products, software solutions and services that enrich people by enabling universal access to knowledge.
                            <br /> <br />
                            Avantel operates from two locations; a state of the art facility and Gold rated Green building certified by IGBC, CII housing the production and R & D facilities at APIIC Industrial Park at Gambheeram Village, Anandapuram Mandal, spread across an area of approximately 55000 Sq Feet. The corporate office is located at Hyderabad and currently operates from leased premises.
                            <br /> <br />
                            The R & D facility is recognized by DSIR, Govt of India as “Center of Excellence” for undertaking research & developmental works in contemporary technologies in the areas of RF design, embedded systems, signal processing and software.
                            <br /> <br />
                            Our Visakhapatnam facility houses various state of the art facilities, equipment and tools required for undertaking R & D and Manufacturing activities.
                        </p>

                        <div className='solutionsDiv'>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>RF Test Equipment</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg17'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>RF Test Equipment</h5>
                                        <p>
                                            <ul>
                                                <li>ADS, EMDS, ACAD, SOLID WORKS and CADSTAR</li>
                                                <li>Digital Transmission Analyzers &amp; Logic Analyzer</li>
                                                <li>Network Analyzers</li>
                                                <li>Spectrum Analyzers up to 26GHz</li>
                                                <li>Vector Signal Generator &amp; Analyzer</li>
                                                <li>Radio Communication Test sets</li>
                                                <li>Signal Generators</li>
                                                <li>High Power Test &amp; Measuring instruments</li>
                                                <li>BER testers</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>Testing & Assembly Facilities</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg18'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Testing & Assembly Facilities</h5>
                                        <p>
                                            <ul>
                                                <li>Clean Room (Class 100)</li>
                                                <li>PCB Fabrication Facility</li>
                                                <li>SMT Assembly</li>
                                                <li>Anechoic Chamber</li>
                                                <li>Environment Test Facilities: </li>
                                                <div className='row ml-3 mt-2'>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <ul>
                                                            <li>High/Low Temperatures</li>
                                                            <li>Humidity</li>
                                                            <li>Vibration</li>
                                                            <li>Shock</li>
                                                        </ul>
                                                    </div>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <ul>
                                                            <li>Altitude</li>
                                                            <li>CATH</li>
                                                            <li>Tropical Exposure</li>
                                                            <li>Thermal Cycling/ Shock</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </ul>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-4 solutionsColLeft'>
                                        {/* <h3>Engineering facilities</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <div className='solutionsBgImg19'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-8 solutionsColRight'>
                                        <h5>Engineering facilities</h5>
                                        <p>
                                            <ul>
                                                <li>CNC Vertical Machining Centers</li>
                                                <li>CNC Turning Lathe Machine</li>
                                                <li>Drill Tap Machine</li>
                                                <li>Soldering, Wire Harness, Potting Facilities</li>
                                                <li>Painting &amp; Chromatisation</li>
                                                <li>3D Printing Facility</li>
                                                <li>Wire cutting machine</li>
                                                <li>Wire drilling Machine</li>
                                                <li>Injection Molding Facility</li>
                                                <li>Surface grinding machine</li>
                                                <li>Cylindrical grinding machine</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrdivison'></hr>
                            <div className='solution'>
                                <div className='row'>
                                    <div className='col-md-12 solutionsColLeft'>
                                        {/* <h3>Engineering facilities</h3> */}
                                        <div className='solutionsBgImgCol'>
                                            <video autoPlay muted loop playsInline preload="auto" className='upcomingFacilitiesVideo'>
                                                <source src="../assets/services/infra/UpcomingFacilities.mp4" type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                    <div className='col-md-12 solutionsColRight p-2'>
                                        <h5>Upcoming facilities</h5>
                                        <p>Avantel is in the process of establishing a new facility at Hyderabad with a built up area of around 70,000 Square feet in an area of 04 acres in E-City, Tukkuguda just few Kilometers away from Rajiv Gandhi International Airport. The plan is to create excellent facilities in terms of infrastructure and equipment to diversify into newer areas in Radar technologies, Small satellite manufacturing and medical devices. The facility is expected to be operationalised by end 2023. The corporate office will also be shifted to own premises spread across an area of 15000 square feet in one of the upcoming business park in Gachibowly financial district.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </main>
                <Footer />
            </div>
        )
    }
}

export default Services